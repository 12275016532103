import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, ListItemText, ListItemIcon } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
import { ListItem } from './styles';
// ----------------------------------------------------------------------
export default function NavItem({ item, open, active, isExternalLink, ...other }) {
    const { title, path, icon, children } = item;
    const renderContent = (_jsxs(ListItem, { active: active, ...other, children: [_jsxs(ListItemIcon, { children: [" ", icon, " "] }), _jsx(ListItemText, { disableTypography: true, primary: title }), !!children && (_jsx(Iconify, { width: 16, icon: open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill', sx: { ml: 1 } }))] }));
    // ExternalLink
    if (isExternalLink) {
        return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", underline: "none", children: renderContent }));
    }
    // Has child
    if (children) {
        return renderContent;
    }
    // Default
    return (_jsx(Link, { component: RouterLink, to: path, underline: "none", children: renderContent }));
}
